import React, {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import * as S from './Jobs.styled';
import ContentSectionLight from "../../components/ContentSectionLight";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import PageTransition from "../../components/PageTransition";
import {renderContentSectionBlock} from "../../components/ContentSectionBlock";
import transComponents from "../../style/transComponents";
import JobItem from "../../components/JobItem";
import Spacer from "../../components/Spacer";

const renderItem = (job, index) => {
    return (
        <JobItem job={job} key={index} defaultIsOpen={index === 0}/>
    )
}

function Jobs() {
    const {t} = useTranslation();

    useEffect(() => {
        setTimeout(() => {
            window.scrollTo({top: 0, behavior: 'smooth'});
        }, 1);
    }, [])

    return (
        <S.Wrapper>
            <S.Hero>
                <Header/>
                <S.Top>
                    <S.Title>{t('jobs.title')}</S.Title>
                    <S.SubContainer>
                        <S.SubTitle>JOBS</S.SubTitle>
                    </S.SubContainer>
                    <S.HeaderText>
                        {renderContentSectionBlock(t, 'jobs.header.blocks')}
                    </S.HeaderText>
                </S.Top>
            </S.Hero>
            <PageTransition/>

            <S.ContentSectionLight>
                <S.ContentSectionWrapper>
                    <S.ContentPart>
                        <S.ContentTitle>{t('jobs.content.title')}</S.ContentTitle>
                        <S.Jobs>
                            {t('jobs.content.vacancies', { returnObjects: true, components: transComponents }).map((item, i) => renderItem(item, i))}
                        </S.Jobs>

                    </S.ContentPart>
                </S.ContentSectionWrapper>
            </S.ContentSectionLight>


            <ContentSectionLight/>

            <Spacer light/>

            <Footer/>

        </S.Wrapper>
    );
}

export default Jobs;
