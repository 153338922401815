import React, { useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import * as S from './ContentSectionLight.styled';
import transComponents from '../../style/transComponents';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faFacebookSquare,
    faInstagram,
} from '@fortawesome/free-brands-svg-icons';

function ContentSectionLight({ showAboutTalk = false }) {
    const { t } = useTranslation();

    const aboutPauseItems = useMemo(() => {
        const translations = t('about_pause.blocks', {
            returnObjects: true,
            components: transComponents,
        });

        const half = Math.ceil(translations.length / 2);

        return {
            list: translations,
            firstHalf: translations.slice(0, half),
            secondHalf: translations.slice(-half),
        };
    }, [t]);

    return (
        <S.ContentSectionLight>
            <S.ContentSectionWrapper>
                {showAboutTalk && (
                    <S.ContentPart>
                        <S.ContentBlock>
                            <S.ContentTitle>
                                {t('about_talk_component.title')}
                            </S.ContentTitle>
                            <S.AboutTalkComponent>
                                <S.AboutTalkInfo>
                                    <S.AboutTalkInfoTextContainer>
                                        {t('about_talk_component.blocks', {
                                            returnObjects: true,
                                            components: transComponents,
                                        }).map((item, i) => (
                                            <S.ContentText key={i}>
                                                <Trans
                                                    i18nKey={item}
                                                    components={transComponents}
                                                />
                                            </S.ContentText>
                                        ))}
                                    </S.AboutTalkInfoTextContainer>
                                    <S.AboutTalkVideoContainer>
                                        <div style={{ position: 'relative',  paddingTop: '56.25%'}}>
                                            <iframe 
                                                src="https://iframe.mediadelivery.net/embed/192370/8e682c28-afe4-4a54-aa3f-e6a66b3e8754?autoplay=false&loop=false&muted=false&preload=false&responsive=true" 
                                                loading="lazy" 
                                                style={{border: '0', position: 'absolute', top: '0', height: '100%', width: '100%'}} 
                                                allowfullscreen="true"
                                                title="contentLightPreview">
                                            </iframe>
                                        </div>
                                    </S.AboutTalkVideoContainer>
                                </S.AboutTalkInfo>
                                <S.AboutTalkButtonsContainer>
                                    <S.Button fill as={Link} to="/">
                                        {t('generic.read_more')}
                                    </S.Button>
                                    <S.Button outline href="/#previous-talks">
                                        {t('generic.past_talks')}
                                    </S.Button>
                                </S.AboutTalkButtonsContainer>
                            </S.AboutTalkComponent>
                        </S.ContentBlock>
                    </S.ContentPart>
                )}

                <S.ContentPart>
                    <S.ContentBlock>
                        <S.ContentTitle>
                            {t('about_pause.title')}
                        </S.ContentTitle>
                        <S.AboutPauseText pause={true}>
                            <ul>
                                {aboutPauseItems.firstHalf.map((item, i) => (
                                    <li key={`about_pause-block-${i}`}>
                                        <strong>PAUSE</strong>{' '}
                                        <Trans
                                            i18nKey={item}
                                            components={transComponents}
                                        />
                                    </li>
                                ))}
                            </ul>
                            <ul>
                                {aboutPauseItems.secondHalf.map((item, i) => (
                                    <li key={`about_pause-block-${i}`}>
                                        <strong>PAUSE</strong>{' '}
                                        <Trans
                                            i18nKey={item}
                                            components={transComponents}
                                        />
                                    </li>
                                ))}
                            </ul>
                        </S.AboutPauseText>
                        <S.BottomButtons>
                            <S.LinkButtonGroup>
                                <S.Button fill as={Link} to="/faq">
                                    {t('generic.faq')}
                                </S.Button>
                                {/* <S.Button fill as={Link} to="/jobs">
                                    {t('generic.jobs')}
                                </S.Button> */}
                                <S.Button
                                    outline
                                    href="mailto:info@pause-invites.com"
                                >
                                    {t('generic.contact')}
                                </S.Button>
                            </S.LinkButtonGroup>
                            <S.SocialButtonGroup>
                                <S.SocialButton
                                    target="_blank"
                                    href="https://www.facebook.com/PAUSE.talks"
                                >
                                    <FontAwesomeIcon icon={faFacebookSquare} />
                                </S.SocialButton>
                                <S.SocialButton
                                    target="_blank"
                                    href="https://instagram.com/pause.invites"
                                >
                                    <FontAwesomeIcon icon={faInstagram} />
                                </S.SocialButton>
                            </S.SocialButtonGroup>
                        </S.BottomButtons>
                    </S.ContentBlock>
                </S.ContentPart>
            </S.ContentSectionWrapper>
        </S.ContentSectionLight>
    );
}

export default ContentSectionLight;
