module.exports = {
  "top_pre_title": "PAUSE le invita a:",
  "top_title": "Stephen Hawking's <br /> explanation of time",
  "top_author": "por Thomas Hertog",
  "buy_ticket": "Compre su Ticket",
  "watch_trailer": "Vea el tráiler",
  "more_info": "Más información",
  "about_the_talk": "Sobre la charla",
  "about_the_talk_content_text_1": "¿Qué es el tiempo? Nunca pensamos en ello, pero el tiempo podría ser uno de los misterios más grandes del universo. En esta charla extraordinaria y única podrá conocer las alucinantes ideas de <strong>Stephen Hawking</strong>, que sobrepasan la ciencia ficción",
  "about_the_talk_content_text_2": "PAUSE invita al profesor Thomas Hertog, un viejo amigo y estrecho colaborador de Stephen Hawking, y un <strong>genio de la física teórica</strong>. Poco antes de la muerte de Stephen, estaban trabajando en un último artículo revolucionario.",
  "about_the_talk_content_text_3": "PAUSE colabora con la <strong>Fundación Stephen Hawking</strong> y da la bienvenida al profesor Thomas Hertog, quien aceptó excepcionalmente dar esta charla, en un escenario cinematográfico único.",
  "about_the_talk_content_text_4": "",
  "stephen_hawking_content_text_1": "Stephen Hawking era el científico más brillante de nuestro tiempo y un visionario extraordinario. En la charla se tratarán cuestiones tales como:",
  "stephen_hawking_content_list_item_1": "¿Es el tiempo lo que creemos que es?",
  "stephen_hawking_content_list_item_2": "¿Podrán hacerse realidad los viajes interestelares?",
  "stephen_hawking_content_list_item_3": "¿Cómo podríamos viajar en el tiempo?",
  "stephen_hawking_content_list_item_4": "¿Qué es lo desconcertante de la paradoja de la pérdida de información?",
  "stephen_hawking_content_list_item_5": "¿Es la vida alienígena tan solo ciencia ficción?",
  "stephen_hawking_content_list_item_6": "¿Cuál es el misterio más reciente en el espacio?",
  "practical_info": "Información práctica",
  "practical_info_content_list_item_1": "La venta de tickets comenzará el sábado 13 de marzo a las 17:00 UTC+1 (CET) . En nuestra <1>ticket page</1> encontrará toda la información",
  "practical_info_content_list_item_2": "Esta charla en línea se estrenará en un solo momento: Martes 30 de marzo a las 20:00 horas (hora local)",
  "practical_info_content_list_item_3": "Junto con su ticket recibirá su código secreto por correo electrónico",
  "practical_info_content_list_item_4": "¿Preguntas? Consulte nuestras <1>FAQ</1> o <3>contact us</3>",
  "stephen_hawking_quote": "La mayoría de la gente cree que la ciencia es demasiado difícil y complicada de entender. Pero no creo que sea así.",
  "previous_edition": "Edición anterior",
  "previous_edition_content_text_1": "Para su primera edición, <strong>PAUSE</strong> invitó a <strong>Dirk De Wachter</strong>, catedrático belga, reconocido psiquiatra y estrella de rock intelectual. La charla tuvo lugar en un escenario fascinante: un faro desolado, un rayo de esperanza en aguas tormentosas y el catalizador de nuestro tema.",
  "previous_edition_content_text_2": "Vivimos en tiempos en los que “la presión por tener éxito” se ha vuelto muy alta. Dirk De Wachter analizó cómo podemos luchar por una vida significativa, basándose en su experiencia profesional de más de 35 años. Esta charla fue en neerlandés, pero todas las siguientes serán en inglés y con sub^tulos en varios idiomas",
  "previous_edition_content_text_3": "Esta charla de PAUSE se convirtió en una de las más importantes en línea.",
  "about_pause": "About Pause",
  "about_pause_content_list_item_1": "<strong>PAUSE</strong> un momento de tranquilidad en medio de todos los estímulos",
  "about_pause_content_list_item_2": "<strong>PAUSE</strong> fue fundada por un grupo de artistas y científicos.",
  "about_pause_content_list_item_3": "<strong>PAUSE</strong> invita a oradores públicos destacados para una charla puntual sobre un tema que despierte nuestras mentes",
  "about_pause_content_list_item_4": "<strong>PAUSE</strong> selecciona un lugar fascinante que sirve de catalizador para el tema del orador.",
  "about_pause_content_list_item_5": "<strong>PAUSE</strong> es totalmente independiente y no tiene ninguna orientación política.",
  "contact_us": "Contacta con nosotros",
  "faq_title": "Have any questions?",
  "check_faq": "Consulta nuestras FAQ"
}
