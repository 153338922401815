import styled from 'styled-components';

import ContentSpacer from "../Spacer";
export {ContentText} from '../ContentSection/ContentSection.styled'

export const JobItem = styled.div`
  color: #000;
  padding-bottom: 2rem;
  border-bottom: 1px dashed ${(props) => props.theme.main.secondaryColor};
`;

export const Title = styled.button`
  font-weight: 700;
  line-height: 150%;
  padding: 2rem 0 0 0;
  text-align: left;
  display: flex;
  justify-content: space-between;
  width: 100%;
  font-size: 2.4rem;
  font-family: 'Fira Sans', sans-serif;

  > svg {
    color: ${(props) => props.theme.main.secondaryColor};
    margin: 6px 0 0 10px;
  }

  @media screen and (max-width: ${(props) => props.theme.layout.bpM}) {
    font-size: 1.8rem;
  }
`;

export const JobCategoryTitle = styled.h2`
  font-size: 2rem;
  font-weight: 900;
  margin: 2rem 0 1rem 0;
`

export const Text = styled.div`
  font-size: 1.8rem;
  line-height: 2.6rem;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.4s ease;
  ${(props) => props.isOpen && `max-height: ${props.height};`} @media screen and(max-width: ${(props) => props.theme.layout.bpM}) {
  font-size: 1.4rem;
}
`;

export const TextInner = styled.div`
  padding: 14px 0;

  p {
    margin-bottom: 20px;
  }

  > ul,
  ol {
    margin-bottom: 20px;
    padding-left: 30px;

    li {
      margin-bottom: 20px;

      &:last-child {
        margin: 0;
      }
    }
  }

  > ol {
    list-style-type: decimal;
  }
`;

export const Spacer = styled(ContentSpacer)`
  padding: 2rem 0 0 0;
`
