import styled from 'styled-components';
import HeroHeader from '../../components/HeroHeader/HeroHeader';
import {
    ContentPart as CP,
    ContentSectionLight as CSL,
    ContentSectionWrapper as CSW,
    ContentTitle as T,
} from '../../components/ContentSectionLight/ContentSectionLight.styled';
//import {BaseContentWrapper} from "../../style/shared/text.styled";

export const Wrapper = styled(HeroHeader)`
    background-size: 100% auto;
    background-position: top;
`;

export const Hero = styled.div`
    background-color: rgba(0, 0, 0, 0.4);
`;

export const Top = styled(CSW)`
    display: flex;
    align-items: center;
    padding: 0 7rem;
    margin: 0 auto;
    @media screen and (max-width: ${(props) => props.theme.layout.bpM}) {
        padding: 40px 20px;
    }
`;

export const SubContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
`;

export const SubTitle = styled.span`
    font-family: 'Fira Sans', sans-serif;
    text-align: center;
    font-size: 2.4rem;
    color: #fff;
    padding: 0.75rem 1.5rem;
    border-radius: 1rem;
    background: rgba(255, 255, 255, 0.15);
`;

export const HeaderText = styled.div`
    font-family: 'Fira Sans', sans-serif;
    color: #fff;
    padding-top: 5rem;
    font-size: 1.8rem;
    width: 70%;

    @media screen and (max-width: ${(props) => props.theme.layout.bpM}) {
        width: 100%;
    }
`;

export const Items = styled.div`
    margin-top: 20px;
`;

export const ContentSectionLight = styled(CSL)``;

export const ContentSectionWrapper = styled(CSW)`
    align-items: center;
`;

export const Title = styled.h1``;

export const ContentTitle = styled(T)`
    color: #000;
    font-family: 'Fira Sans', sans-serif;
    font-size: 2.5rem;
    padding-bottom: 5rem;
    border-bottom: 1px dashed ${(props) => props.theme.main.secondaryColor};
`;

export const Jobs = styled.div`
    display: flex;
    flex-direction: column;
    gap: 5rem;
`;

export const ContentPart = styled(CP)`
    width: 70%;

    @media screen and (max-width: ${(props) => props.theme.layout.bpM}) {
        width: 100%;
    }
    li {
        padding-left: 2rem;
        font-size: 1.8rem;

        &:before {
            content: '◉';
            font-size: 1rem;
            top: 0;
            color: ${(props) => props.theme.main.secondaryColor};
            background: unset;
        }
    }
`;
