const { paylogic, mailInfo } = require('../../../links');
module.exports = {
    talk: {
        author: 'Wim Hof',
    },
    top: {
        pre_title: 'PAUSE nodigt u uit',
        title: 'WIM <strong>HOF</strong>',
        subtitle: 'Can breathing and cold water change your life?',
    },
    generic: {
        date_days: 'd',
        date_hours: 'u',
        date_minutes: 'm',
        date_seconds: 's',
        at: 'at',
        read_more: 'More info',
        faq: 'FAQ',
        jobs: 'Jobs',
        contact: 'Contact',
        about_talk: 'About the talk',
        past_talks: 'Past talks',
    },
    about_talk_component: {
        title: 'About the talk',
        blocks: [
            '<strong>Wim Hof</strong> developed techniques which have a life-changing impact on the mental and physical health of millions of people. In this online PAUSE talk, Wim Hof shares his newest insights - in a truly extraordinary setting.',
            'Cold water and breathing, in combination with the right mindset, strenghten human energy, reduce stress and pain and counterbalance diseases like depressions or chronic inflammations.',
        ],
        video: {
            file: 'ddw.mp4',
            thumbnail: 'video-preview-dirk-de-wachter.webp',
        },
    },
    tickets: {
        no_ticket_yet: 'No ticket yet?',
        no_ticket_yet_for: 'No ticket yet for',
    },
    countdown: {
        premieres_on: 'Premiers on',
        in: 'in',
    },
    block1: {
        title: 'About the talk',
        blocks: [
            '<strong>Wim Hof</strong> developed techniques which have a life-changing impact on the mental and physical health of millions of people. In this online PAUSE talk, Wim Hof shares his newest insights - in a truly extraordinary setting.',
            'Cold water and breathing, in combination with the right mindset, strenghten human energy, reduce stress and pain and counterbalance diseases like depressions or chronic inflammations.',
            'Wim explains how to use his techniques and links them to scientific research. Expect a talk laced with positivity. Wim literally walks on water in Brussels, in front of the Atomium. This as a metaphor for his message that humans can do more than they think.',
            'Wim Hof, who can count DiCaprio or Oprah to his fans, developed his techniques, which led to a stunning number of 26 World records, including swimming the longest distance under ice, climbing the Mount Everest in shorts and running a half marathon barefoot above the pole circle. He believes this method works for everyone of us and his method is based on century-old knowledge.',
            'With this edition, PAUSE supports <strong>independent scientific research</strong> into the Wim Hof Method.',
        ],
    },
    quote: 'This PAUSE talk is going to be a great one. It’s about finding power, happiness and strength through the techniques I have developed. These can change your daily life.',
    quote_author: 'Wim Hof',
    previous_editions: {
        title: 'Previous editions',
        editions: [
            {
                title: 'Dirk De Wachter',
                year: 2022,
                blocks: [
                    'Dirk De Wachter, genius psychiatrist, best-selling author and charismatic public speaker, gave this special PAUSE talk with his newest insights: “Does life mostly have to be about having fun?”',
                    '<strong>We live in times in which “the need to be successful” has become the norm and where we prefer to hide our difficulties.</strong> Dirk De Wachter searches how we can strive for a meaningful life and builds on his professional experience of over 35 years.',
                    'This talk took place in a fascinating setting: a desolate lighthouse, a beacon of hope in stormy water and the catalyst of our subject.',
                    'For this edition, PAUSE supported Te Gek?!, a charity making mental problems discussable.',
                ],
                video: {
                    file: 'ddw.mp4',
                    thumbnail: 'video-preview-dirk-de-wachter.webp',
                },
                quote: {
                    text: 'This PAUSE talk is entirely out of my comfort zone, but it is beautiful. I also find it amazing that the new generation has tuned in massively. These are the people who will shape tomorrow’s world.',
                    author: 'Psychiatrist Dirk De Wachter',
                    avatar: 'avatar-dirk-de-wachter.webp',
                },
            },
            {
                title: 'Stephen Hawking’s collaborator',
                year: 2021,
                blocks: [
                    'What is time? We never think about it, but time might be one of the biggest mysteries in the universe.',
                    'In this extraordinary and one-off talk, insight was gained into the mind-blowing ideas of <strong>Stephen Hawking</strong> that surpass science fiction. PAUSE invited Stephen Hawking’s longtime friend, close collaborator and genius theoretical physicist: Prof. Thomas Hertog. Just before Stephen’s death, they were working on one last revolutionary paper.',
                    'PAUSE supported the official <strong>Stephen Hawking Foundation</strong> and welcomed Prof. Hertog - who exceptionally accepted to give this talk - in a unique cinematographic scenery. People from more than 30 countries watched the talk.',
                ],
                video: {
                    file: 'shf.mp4',
                    thumbnail: 'video-preview-stephen-hawking.webp',
                },
                quote: {
                    text: 'Most people believe that science is too difficult and too complicated for them to understand. But I don’t think this is the case',
                    author: 'Theoretical physicist Stephen Hawking',
                    avatar: 'avatar-stephen-hawking.webp',
                },
            },
        ],
    },
    about_pause: {
        title: 'About Pause',
        blocks: [
            'was founded by a group of artists',
            'invites intellectual rock stars for an exceptional talk.',
            "selects a fascinating location which serves as catalyst for the talk's subject.",
            'donates part of its revenues to a charity or scientific research, linked to the subject of the talk.',
            'is totally independent.',
            ': one moment of tranquility amid all the impulses.',
        ],
    },
    buy_ticket: 'Buy your Ticket',
    already_ticket: 'Already have a ticket?',
    enter_pause_universe: 'Enter Pause Universe',
    available_soon: 'Available soon',
    watch_trailer: 'Watch the trailer',
    more_info: 'More info',
    practical_info: {
        title: 'Practical info',
        blocks: [
            `Ticket sale starts on Saturday January 22 at 10:00 AM CET (UTC+1). You can find all the information on the <anchor href="${paylogic}">ticket website.</anchor>`,
            `This is an <strong>online talk</strong>, whereby the premiere takes place on Wednesday March 2 at 08:00 PM CET (UTC+1). Aren’t you able to join on that day? (Re)watching can in the subsequent days.`,
            'Together with your ticket, you will receive a secret code via email. This code will give you access to the PAUSE platform.',
            'The talk will be subtitled in English or Dutch - depending on your preferences.',
            `Questions? Take a look at our <routerLink to="/faq">FAQ</routerLink> or <anchor href="${mailInfo}">contact us</anchor>.`,
        ],
    },
    contact_us: 'Contact us',
    faq_title: 'Have any questions?',
    check_faq: 'Check our FAQ',
    enter_the_pause_universe: 'ENTER THE <strong>PAUSE </strong>UNIVERSE',
    fill_in_code: 'Enter your unique code',
    your_code_is_valid: 'Your code is Valid!',
    code_error: 'You entered the wrong code. Please try again.',
    too_early:
        'Thanks for entering your code. Right now, it is too soon to log in, please come back at 19:35.',
    too_early_l1: 'Thank you for entering your code. ',
    too_early_l2: 'It is currently too early to log in.',
    too_early_l3: 'Please come back on 02 March 2022 after 19:00.',
    too_early_l4: 'The event will start at 20:00!',
    wrong_code: 'You have an incorrect code. Please try again!',
    faq: [
        {
            title: 'Which charity is chosen for the next PAUSE talk?',
            items: [
                '<p>PAUSE supports independent scientific research into the Wim Hof Method</p>',
                '<p>Our previous talks have supported the following::</p>',
                '<ol>',
                '<li>',
                'The official Stephen Hawking Foundation at the occasion of cosmologist Thomas Hertog’s talk about Time.',
                '</li>',
                '<li>',
                'The non-profit association Te Gek?!, which wants to tear down the taboo around mental well-being, at the occasion of psychiatrist Dirk De Wachter’s talk.',
                '</li>',
                '</ol>',
            ],
        },
        {
            title: 'When does the ticket sale start?',
            items: [
                `<p>Ticket sale starts on Saturday January 22 at 10:00 AM CET (UTC+1). Tickets can be order through <anchor href="${paylogic}">this link.</anchor></p>`,
            ],
        },
        {
            title: 'In which language will the talk be broadcasted?',
            items: [
                '<p>The talk is in English and will be subtitled in Dutch or English - depending on your preference. You can switch language at all time.</p>',
            ],
        },
        {
            title: 'How long will the talk be available to watch, after the premiere?',
            items: [
                '<p>The talk will be available during seven days after the premiere.</p>',
            ],
        },
        {
            title: 'What do I need to watch the talk?',
            items: [
                '<ol>',
                '<li>',
                'A ticket you can order on our ticket page. In the email with the e-ticket you have received after completing your order, you can click through to the talk.',
                '</li>',
                '<li>',
                '<p>A smartphone, tablet, computer or smart-tv to watch the talk. This device must have an up-to-date internet browser.</p>',
                '<p>Desktop: Apple OSX: Safari 12 and up<br />FireFox 71 and up<br />Chrome 81 and up</p>',
                '<p>Desktop: Windows<br />Edge 80 and up<br />FireFox 71 and up<br />Chrome 81 and up</p>',
                '<p>Mobile/tablet: IOS (12 and up)<br />Safari<br />Mobile/tablet: Android (8 and up)<br />Chrome</p>',
                '</li>',
                '<li>A good internet connection, thus fast WiFi, a 4G connection or attached cable.</li>',
                '</ol>',
            ],
        },
        {
            title: 'Can I cast the talk to my TV?',
            items: [
                '<p>Yes, sure you can watch the talk on your TV.<br />Casting the talk is possible in different ways:</p>',
                '<ol>',
                '<li>',
                '<strong>Through Airplay (Apple TV)</strong>',
                '<br />From an iPhone or iPad',
                '<br />Connect your Apple iPhone or iPad to the same WiFi-network as your Apple TV or AirPlay 2-compatible smart TV. Go on your iPhone or iPad to the talk, click on the Airplay icon in the video player and choose the device on which you would like to watch',
                'From a Mac',
                '<br />Connect your Mac to the same WiFi-network as your Apple TV or AirPlay 2-compatible smart TV. Go on your Mac to the talk and click in the video player on the Airplay icon. Choose the device you want to watch on.',
                '</li>',
                '<li>',
                '<strong>Through Chromecast</strong>',
                '<br />Through your Chrome browser (desktop or laptop)',
                '<br />Open Chrome on your computer and go to the talk. Click right up on More and then on Cast. Choose the Chromecast-device on which you want to watch the talk. If you are already using the Chromecast, replace the content which is appearing at that moment on the TV.',
                '<br /><br />Casting your computer screen',
                '<br />You can display your entire computer screen through Chrome on Mac, Windows and Chromebooks. Open therefore Chrome on your computer and go to the talk. Click right up on More and then on Cast. Click above on “Cast to” on the arrow down and choose Cast desktop. Choose the Chromecast device on which you want to watch the talk.',
                '<br /><br />Casting your entire Android screen<br />',
                'Make sure that your mobile or your tablet is connected to the same WiFi-network as the Chromecast device. Open the Google Home-app. Click on the device to which you want to cast the screen. Click on Casting my screen and then click on Casting screen.',
                '</li>',
                '<li>',
                '<strong>Through HDMI cable</strong><br />',
                'Connect the HDMI cable on the output of your computer and on the HDMI input of your TV. Select image control on your operating system TV as output. Turn on the TV and choose the used HDMI input of your TV. Your TV will then instantly display the image and the sound of your computer. Go then via your computer to the talk.',
                '</li>',
                '<li>',
                '<strong>Through your smart-tv</strong><br />',
                'Go to the menu of your smart-tv to the application of the internet browser. Surf on the  web to https://pause-invites.com to open this page on your television. Press the play button to play the talk.',
                '</li>',
                '</ol>',
            ],
        },
        {
            title: 'My talk won’t start!',
            items: [
                '<p>',
                'It has probably to do with the cookies in your browser. Delete them, connect then your browser and try again. The talk might be blocked by an AdBlocker or antivirus programme. Turn off your AdBlocker and/or make an exception for the talk in your antivirus programme.',
                "<br /><br />If this doesn't work, try to watch the talk in another browser. Do you use Chrome and have you got extensions collecting data which have been installed? Turn them off, please. If this doesn't help, please update your browser.",
                '</p>',
            ],
        },
        {
            title: 'The talk is faltering or stops',
            items: [
                '<p>Check if your internet connection is okay. If this is not the problem, choose then a lower display quality with the quality selection icon in the play bar. Should this not help, start the talk over again. </p>',
            ],
        },
        {
            title: 'The talk is blocked in my browser',
            items: [
                '<p>The talk is probably being blocked by an AdBlocker or antivirus programme. Turn off your AdBlocker and/or make an exception for the talk in your antivirus programme. If this doesn’ t help, update your browser.</p>',
            ],
        },
        {
            title: 'The images are displayed but not the sound',
            items: [
                '<p>',
                'Check in the video player if the sound is not on ‘mute’ through the sound icon in the play bar. If the sound in the video player is on, check then in the settings of the device to which sound source the sound is being sent.',
                '<br /><br />Maybe your Bluetooth headset is connected to your device and in order to play the sound through the device, you have to put your output channel on the device instead of on the headset.',
                '</p>',
            ],
        },
    ],
    enter_name_for_chat: 'Enter name for chat',
    type_name: 'Your name',
    type_message: 'Enter message...',
    chat_closed: 'Chat is closed...',
    jobs: {
        title: 'PAUSE vacancy',
        header: {
            blocks: [
                '<strong>PAUSE</strong> is an online platform inviting the most compelling intellectual rock stars of our time for a one off talk in a cinematographic setting. Invitees include ‘the iceman’ Wim Hof, psychiatrist Dirk De Wachter and Stephen Hawking’s closest collaborator.',
                '<strong>IIIMAGINE</strong> organises extraordinary electronic music shows at inspiring locations whereby new music is composed by Curtis Alto for every edition. Previous shows include Castle Gravensteen, the Royal Aviation Hall and Rubens House. We strive to imprint the memories of the IIIMAGINE shows on our guests’ minds for the next 50 years.',
            ],
        },
        content: {
            title: 'Open Vacancies',
            your_role: 'Your role',
            requirements: 'Requirements',
            vacancies: [
                {
                    title: 'Digital Creative (full-time)',
                    blocks: [
                        'We are looking for an enthusiastic and experienced <strong>digital creative</strong> to join our team. We believe both IIIMAGINE and PAUSE have the potential to change people’s lives for the better and are looking for someone who wants to build with us on this dream. We believe that a very strong team can accomplish a great deal. ',
                    ],
                    role: [
                        'IIIMAGINE and PAUSE are about artistic, cool and meaningful stories. You will bring them online. Join our thinking process about new digital ideas, develop storylines into content and contribute to the growth of our digital platforms. We will coach you to be immersed into the vision of both IIIMAGINE and PAUSE and give you a lot of freedom.',
                        'Be involved with our collaborations: with visual artists, musicians, light and stage designers, painters, fashion designers, etc',
                        'Create graphic designs for social media posts. We are not talking about cheap giveaway actions but about creating thoughtful content.',
                        'Edit videos for social media posts.',
                        'You have an eye for aesthetics and could envisage directing that vision to the photographers, videographers and other artists during the shows and filming days.',
                    ],
                    requirements: [
                        'You are interested in art, new technologies and music and have a broad interest in general',
                        'You have a first experience, preferably in a dynamic, creative and tech-driven environment ',
                        'You have a strong interest in meaningful online and offline content & stories',
                        'You have a knowledge of Premiere, Photoshop, After Effects and are eager to learn a lot',
                        'You are fluent in Dutch and English (written/spoken) and have a skill to put feelings into words',
                        'You want to work in a startup environment and take ownership of what you do ',
                        'You can come to our office in Ghent',
                        'We are looking for someone who is good, in addition to being excellent at what she/he does!',
                    ],
                    video: {
                        file: 'III_DC_VACATURE_v02.mp4',
                        thumbnail: 'video-preview-III-DC-VACATURE.webp',
                    },
                    end_blocks: [
                        `Interested? Send us an email with your cover letter + CV at <br/><anchor href="${mailInfo}">info@pause-invites.com</anchor>`,
                    ],
                },
            ],
        },
    },
    days: 'Days',
    hours: 'Hours',
    minutes: 'Minutes',
    seconds: 'Seconds',
    continue: 'Continue',
    questions_take_a_look: `Questions? Take a look at our <2>FAQ</2> or <5>contact us</5>`,
};
