const testDates = {
    startSaleDate: new Date('2030-11-18T09:45:00+01:00'),
    checkTicketDate: new Date('2030-12-27T18:50:00+01:00'),
    enterDate: new Date('2030-12-27T18:52:00+01:00'),
    eventDate: new Date('2023-12-28T20:00:00+01:00'),
    vodDate: new Date('2030-12-27T19:15:00+01:00'),
};

const productionDates = {
    startSaleDate: new Date('2030-11-18T10:00:00+01:00'),
    checkTicketDate: new Date('2030-12-28T09:00:00+01:00'),
    enterDate: new Date('2030-12-28T18:00:00+01:00'),
    eventDate: new Date('2023-12-28T20:00:00+01:00'), // for countdown on homepage
    vodDate: new Date('2030-12-28T21:30:00+01:00'),
};

const isTesting = process.env.REACT_APP_ENV === 'test';

export const checkTicketDate = isTesting
    ? testDates.checkTicketDate
    : productionDates.checkTicketDate;
export const enterDate = isTesting
    ? testDates.enterDate
    : productionDates.enterDate;
export const eventDate = isTesting
    ? testDates.eventDate
    : productionDates.eventDate;
export const startSaleDate = isTesting
    ? testDates.startSaleDate
    : productionDates.startSaleDate;
export const vodDate = isTesting ? testDates.vodDate : productionDates.vodDate;
