import React from 'react';
import * as S from './BottomSection.styled';
import pauseTitleImg from '../../assets/img/pause-titel-large.png';
import { paylogic } from '../../links';
import { useTranslation } from 'react-i18next';

function BottomSection() {
    const { t } = useTranslation();

    return (
        <>
            <S.SvgContainer>
                <S.SvgLine
                    preserveAspectRatio="none"
                    viewBox="0 0 100 100"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <line x1="0" y1="100" x2="100" y2="0" />
                </S.SvgLine>
            </S.SvgContainer>
            <S.BottomSection>
                <S.PauseTitle>
                    <img src={pauseTitleImg} alt="" />
                </S.PauseTitle>
                <S.TicketSection>
                    <S.TicketBlockTitle>
                        {t('tickets.no_ticket_yet_for')} {t('talk.author')}?
                    </S.TicketBlockTitle>
                    <S.ActionButton disabled href={paylogic} target="_blank">
                        {t('buy_ticket')}
                    </S.ActionButton>
                </S.TicketSection>
            </S.BottomSection>
        </>
    );
}

export default BottomSection;
