import React, { useEffect } from 'react';
import * as S from './Home.styled';
import Header from '../../components/Header';
import HeroContent from '../../components/HeroContent';
import ContentSection from '../../components/ContentSection';
import ContentSectionLight from '../../components/ContentSectionLight';
import Footer from '../../components/Footer';
import BottomSection from '../../components/BottomSection';
import { useLocation } from 'react-router';
import { checkCoupon } from '../../Echo';
import { useHistory } from 'react-router-dom';
import PreviousEditions from '../../components/PreviousEditions/PreviousEditions';
import HeroHeader from '../../components/HeroHeader/HeroHeader';
import PageTransition from '../../components/PageTransition';
import { checkTicketDate } from '../../dates';

function Home() {
    const location = useLocation();
    const history = useHistory();

    useEffect(() => {
        let code;
        const query = new URLSearchParams(location.search);
        if ((code = query.get('coupon'))) {
            window.localStorage.setItem('coupon', query.get('coupon'));
            if (checkTicketDate < new Date()) {
                checkCoupon(code, 'pause-invites').then((res) => {
                    if (res.ok) {
                        history.push('/event');
                    }
                });
            }
        }
    }, [history, location.search]);

    return (
        <S.Home>
            <HeroHeader home>
                <Header />
                <HeroContent />
            </HeroHeader>
            <ContentSection />
            <PageTransition />
            <ContentSectionLight />
            <BottomSection />
            <PageTransition dark />
            <PreviousEditions />
            <Footer />
        </S.Home>
    );
}

export default Home;
