import React from 'react';
import * as S from './Footer.styled';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faFacebookSquare,
    faInstagram,
} from '@fortawesome/free-brands-svg-icons';
import { mailInfo } from '../../links';

function Footer() {
    const { t } = useTranslation();

    return (
        <S.Footer>
            <S.Content>
                <S.Copyright>© {new Date().getFullYear()} PAUSE</S.Copyright>
                <div>
                    <S.Link as={Link} to="/faq">
                        {t('generic.faq')}
                    </S.Link>
                </div>
                {/* <div>
                    <S.Link as={Link} to="/jobs">{t('generic.jobs')}</S.Link>
                </div> */}
                <div>
                    <S.Link as={'a'} href={mailInfo}>
                        {t('generic.contact')}
                    </S.Link>
                </div>
                <S.SocialButtonGroup>
                    <S.SocialButton
                        target="_blank"
                        href="https://www.facebook.com/PAUSE.talks"
                    >
                        <FontAwesomeIcon icon={faFacebookSquare} />
                    </S.SocialButton>
                    <S.SocialButton
                        target="_blank"
                        href="https://instagram.com/pause.invites"
                    >
                        <FontAwesomeIcon icon={faInstagram} />
                    </S.SocialButton>
                </S.SocialButtonGroup>
            </S.Content>
        </S.Footer>
    );
}

export default Footer;
