import styled from 'styled-components';

export const Spacer = styled.div`
  padding: 2.5rem 0;
  width: 100%;
  height: 100%;
  min-height: 1px;
  
  ${props => props.light ? 'background-color: #fff;' : ''}
`;
