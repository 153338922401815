module.exports = {
  "top_pre_title": "PAUSE vous remercie de joindre notre conférence précédente:",
  "top_title": "Stephen Hawking<br /> et le mystère du temps",
  "top_author": "par Thomas Hertog",
  "buy_ticket": "Achetez votre Billet",
  "watch_trailer": "Regardez la bande-annonce",
  "more_info": "Plus d'infos",
  "about_the_talk": " A PROPOS DE LA CONFÉRENCE PRÉCÉDENTE",
  "about_the_talk_content_text_1": "Qu'est-ce que le temps? Nous n'y songeons pas, mais nous disons qu’il est ‘temps d’y aller’, ‘le temps c’est de l’argent,’ ‘le temps file’ et que ‘le temps diminue’. Mais qu'est-ce que le temps? Le temps pourrait bien être un des plus grands mystères de l’univers.",
  "about_the_talk_content_text_2": "Dans cette conférence extraordinaire et unique, les idées époustouflantes de <strong>Stephen Hawking</strong> qui dépassent la science-fiction, étaient présentées.",
  "about_the_talk_content_text_3": "PAUSE a invité l’ami de longue date de Stephen Hawking, son proche collaborateur et physicien théoricien génial: le Prof. Thomas Hertog. Peu avant le décès de Stephen, ils travaillaient ensemble à un ultime article révolutionnaire.",
  "about_the_talk_content_text_4": "PAUSE a collaboré avec <strong>la Fondation officielle Stephen Hawking</strong> et a accueilli le Prof. Thomas Hertog - qui a exceptionnellement accepté de donner cette conférence - dans un cadre cinématographique unique. Des personnes de plus de 20 pays ont rejoint la conférence.",
  "stephen_hawking_content_text_1": "Stephen Hawking était le scientifique le plus brillant de notre époque et un rêveur extraordinaire. L’exposé était le reflet de questions telles que:",
  "stephen_hawking_content_list_item_1": "Le temps est-il ce que nous pensons qu’il est?",
  "stephen_hawking_content_list_item_2": "Les voyages interstellaires pourraient-ils se faire?",
  "stephen_hawking_content_list_item_3": "Comment pourrions-nous voyager dans le temps?",
  "stephen_hawking_content_list_item_4": "Qu’y a-t-il de si déroutant dans le paradoxe de l'information? ",
  "stephen_hawking_content_list_item_5": "La vie extraterrestre est-elle simplement de la science-fiction?",
  "stephen_hawking_content_list_item_6": "Quel est le dernier mystère de l’espace?",
  "practical_info": "INFO PRATIQUES",
  "practical_info_content_list_item_1": "Nous espérons vous pouvoir accueillir bientôt pour une nouvelle édition.",
  "practical_info_content_list_item_2": "Ceci est une conférence en ligne qui était diffusée à un seul moment: le mardi 30 mars à 20:00, heure locale à Bruxelles - Paris - Amsterdam et à Londres. Des personnes de plus de 20 pays ont rejoint la conférence. Nous espérons vous pouvoir accueillir bientôt pour une nouvelle édition",
  "practical_info_content_list_item_3": "Avec votre ticket, vous avez reçu votre code secret par e-mail.",
  "practical_info_content_list_item_4": "Des questions? Consultez notre <1>FAQ</1> ou <3>contactez-nous</3>.",
  "stephen_hawking_quote": "La plupart des gens croient que la science est trop difficile et compliquée à comprendre. Mais je ne pense pas que ce soit le cas",
  "previous_edition": "PREMIÈRE ÉDITION",
  "previous_edition_content_text_1": "Pour la première édition, <strong>PAUSE</strong> a invité <strong>Dirk De Wachter</strong>, le Professeur belge, psychiatre réputé et rockstar intellectuelle. La conférence a eu lieu dans un cadre unique: un phare abandonné - un symbole d’espoir en ces temps mouvementés, et ainsi le catalyseur du sujet. Nous vivons des temps où “le besoin de réussite” est très grand. Dirk De Wachter a examiné comment aspirer à une vie accomplie et s’est basé sur son expérience professionnelle de plus de 35 ans. Cette conférence était en néerlandais. Cependant, les prochaines conférences se feront en anglais et seront sous-titrées en français (e.a.).",
  "previous_edition_content_text_2": "PAUSE a soutenu <strong>l'œuvre caritative</strong> Te Gek?!, promouvant le bien-être mental des jeunes.",
  "previous_edition_content_text_3": "Cette conférence de PAUSE est devenue l’une des plus grandes en ligne.",
  "about_pause": "A PROPOS DE PAUSE",
  "about_pause_content_list_item_1": "<strong>PAUSE</strong> considère les experts les plus réputés au monde comme des artistes et les conférences comme des spectacles. ",
  "about_pause_content_list_item_2": "<strong>PAUSE</strong> choisit un lieu fascinant qui sert de catalyseur pour le sujet de la conférence.",
  "about_pause_content_list_item_3": "<strong>PAUSE</strong> fait don d’une partie de ses revenus à une œuvre caritative, liée au thème de la conférence.",
  "about_pause_content_list_item_4": "<strong>PAUSE</strong> fondée par un groupe d’artistes et de scientifiques, est une organisation totalement indépendante.",
  "about_pause_content_list_item_5": "<strong>PAUSE</strong> un instant de paix au milieu de l’agitation.",
  "contact_us": "Contactez-nous",
  "check_faq": "Check our FAQ",
  "enter_the_pause_universe": "Entrez dans l'univers de PAUSE",
  "fill_in_code": "Introduisez votre code personnel",
  "code_error": "Vous avez introduit le mauvais code personnel. Veuillez réessayer.",
  "too_early": "Merci d'introduire votre code. En ce moment il est encore trop tôt de vous connecter; veillez revenir à partir de 19:35 svp.",
  "faq": [
    {
      "title": "Quelle est l'association caritative choisie?",
      "items": ["<p>PAUSE soutient la Fondation officielle Stephen Hawking.</p>"]
    },
    {
      "title": "Quand démarre la vente de tickets?",
      "items": ["<p>Le samedi 13 mars à 17:00 UTC+1 (CET) .</p>"]
    },
    {
      "title": "Dans quelle langue la conférence sera diffusée?",
      "items": ["<p>La conférence est en anglais mais sera, selon votre choix, sous-titrée en français, anglais, néerlandais et espagnol.</p>"]
    },
    {
      "title": "Combien de temps la conférence sera-t-elle disponible en ligne après la première?",
      "items": ["<p>La conférence sera disponible pendant une semaine après la première diffusée le samedi 30 mars 2021.</p>"]
    },
    {
      "title": "De quoi ai-je besoin pour visionner la conférence?",
      "items": [
        "<ol>",
        "<li>",
        "Un ticket que vous pouvez commander via notre page de vente de tickets. A partir du mail de confirmation et du e-ticket que vous recevrez après avoir complété votre commande, vous pourrez cliquer pour démarrer la vidéo.",
        "</li>",
        "<li>",
        "<p>Un smartphone, une tablette, un ordinateur ou une télévision connectée pour visionner la vidéo. Cet appareil doit disposer d’un internet browser à jour.</p>",
        "<p>Desktop: Desktop: Apple OSX: Safari 12 et ultérieur<br />FireFox 71 et ultérieur<br />Chrome 81 et ultérieur</p>",
        "<p>Desktop: Windows<br />Edge 80 et ultérieur<br />FireFox 71 et ultérieur<br />Chrome 81 et ultérieur</p>",
        "<p>Portable/tablette: IOS (12 et ultérieur)<br />Safari<br />Portable /tablette: Android (8 et ultérieur)<br />Chrome</p>",
        "</li>",
        "<li>Une bonne connexion internet, donc WIFI rapide, une connexion 4G ou un câble fixe.</li>",
        "</ol>"
      ]
    },
    {
      "title": "Quelle est la diffėrence entre Vague 1, Vague 2 et Vague 3?",
      "items": ["<p>Il s’agit de la même conférence, mais plus vite vous commandez votre billet, plus avantageux sera le prix.</p>"]
    },
    {
      "title": "Puis-je caster la conférence sur mon téléviseur?",
      "items": [
        "<p>Bien sûr. Pour caster la vidéo en ligne, vous avez plusieurs options:</p>",
        "<ol>",
        "<li>",
        "<strong>Via Airplay (Apple TV)</strong>",
        "<br />A partir d’un iPhone ou iPad",
        "<br />Connectez votre iPhone ou iPad au même réseau WiFi que votre Apple TV ou télévision connectée (Smart TV) qui est AirPlay 2 compatible. Allez via votre iPhone ou iPad vers la vidéo, cliquez sur l’icône Airplay dans la vidéo et choisissez l’appareil sur lequel vous souhaitez visionner.",
        "A partir d'un Mac",
        "<br />Connectez votre Mac au même réseau WiFi que votre Apple TV ou votre télévision connectée (Smart TV) qui est AirPlay 2 compatible. Sur le Mac allez vers la vidéo et cliquez dans la vidéo sur l’icône Airplay. Choisissez l’appareil sur lequel vous souhaitez visionner.",
        "</li>",
        "<li>",
        "<strong>Via Chromecast</strong>",
        "<br />Via votre browser Chrome (bureau et ordinateur portable)",
        "<br />Ouvrez Chrome sur votre ordinateur et allez vers la vidéo. Cliquez en haut à droite sur Plus et ensuite sur Caster. Choisissez l'appareil Chromecast sur lequel vous souhaitez visionner la vidéo. Si vous utilisez déjà Chromecast, le contenu modifie ce qui s’affiche à ce moment sur votre téléviseur.",
        "<br /><br />Caster l’écran de votre ordinateur",
        "<br />Vous pouvez afficher l’écran complet de votre ordinateur via Chrome sur Mac, Windows et Chromebooks. A cette fin, ouvrez Chrome sur votre ordinateur et allez vers la vidéo. Cliquez en haut à droite sur Plus et ensuite sur Caster. Cliquez en haut à côté de 'Caster  sur la flèche vers le bas et choisissez Caster Bureau. Choisissez l’appareil Chromecast sur lequel vous désirez  visionner la vidéo.",
        "<br /><br />Caster votre écran Android complet<br />",
        "Veillez à ce que votre portable ou votre tablette soit connecté au même réseau WiFi que l’appareil Chromecast. Ouvrez l’application Google Home.Tapez sur l’appareil vers lequel vous voulez caster l’écran. Tapez sur Caster Mon Ecran et ensuite sur Caster Ecran.",
        "</li>",
        "<li>",
        "<strong>Via un câble HDMI</strong><br />",
        "Connectez le câble HDMI  à la sortie de votre ordinateur et à l’entrée HDMI de votre TV. Sélectionnez dans Paramètres d’images de votre système d’exploitation TV comme sortie. Allumez votre TV et choisissez l’entrée HDMI déjà utilisée de votre TV comme entrée. Ensuite, votre téléviseur rendra immédiatement l’image et le son de votre ordinateur. Allez ensuite via votre ordinateur vers la vidéo.",
        "</li>",
        "<li>",
        "<strong>Via ta télévision connectée (Smart TV)</strong><br />",
        "Allez dans le menu de votre télévision connectée vers l’application de votre système d’exploitation. Surfez via l’internet sur https://pause-invites.com pour ouvrir cette page sur votre télévision. En appuyant sur la touche play, la vidéo sera diffusée.",
        "</li>",
        "</ol>"
      ]
    },
    {
      "title": "Ma vidéo ne démarre pas!",
      "items": [
        "<p>",
        "Cela est sans doute dû aux cookies dans votre browser. Supprimez-les, fermez ensuite votre browser et essayez à nouveau. Il est possible que la vidéo soit bloquée par un AdBlocker ou programme anti-virus. Désactivez AdBlocker ou autorisez uniquement la diffusion de la vidéo dans votre programme anti-virus.",
        "<br /><br />Si ceci ne réussit pas, essayez de visionner la vidéo dans un autre browser. Utilisez-vous Chrome et avez-vous installé des extensions qui collectent des données? Désactivez-les. Si ce qui précède ne vous aide pas, mettez votre browser à jour.",
        "</p>"
      ]
    },
    {
      "title": "La diffusion faiblit et se bloque",
      "items": ["<p>Vérifiez que votre connexion internet soit bonne. Si le problème est ailleurs, choisissez une qualité de diffusion inférieure au moyen de l'icône sélecteur de qualité dans la barre de lecture. Si le problème n'est pas encore résolu, supprimez les cookies et relancez la diffusion.</p>"]
    },
    {
      "title": "La diffusion se bloque dans mon browser",
      "items": ["<p>Il est possible que la diffusion soit bloquée par un AdBlocker ou un programme anti-virus. Désactivez votre AdBlocker et/ou faites une exception pour la vidéo dans votre programme anti-virus. Si ce qui précède n’est pas utile, mettez votre browser à jour.</p>"]
    },
    {
      "title": "J’ai l’image mais pas le son",
      "items": [
        "<p>",
        "Vérifiez dans la vidéo au moyen de l'icône de son que “muet” ne soit pas enclenché. Si le son est activé dans la vidéo, contrôlez dans les paramètres de l’appareil vers quelle source sonore le son est envoyé.",
        "<br /><br />Il est par exemple possible que votre casque Bluetooth soit relié à votre appareil et pour écouter le son via votre appareil, il faudra mettre le canal “output” sur l'appareil au lieu du casque.",
        "</p>"
      ]
    }
  ]
}
