import React from 'react';
import * as S from './Spacer.styled';

function Spacer({children, className, light}) {
    return (
        <S.Spacer className={className} light>
            {children}
        </S.Spacer>
    );
}

export default Spacer;
