import React from 'react';
import { useTranslation } from 'react-i18next';
import * as S from './BuyTicket.styled';
import { paylogic, linktree } from '../../links';

function BuyTicket() {
    const { t } = useTranslation();
    return (
        <S.TicketSection>
            <S.TicketBlockTitle>
                {t('tickets.no_ticket_yet')}
            </S.TicketBlockTitle>
            <S.ActionButton disabled href={paylogic} target="_blank">
                {t('buy_ticket')}
            </S.ActionButton>
            <S.TicketBlockContent>
                <a href={linktree} target="_blank" without rel="noreferrer">
                    {t('more_info')}
                </a>
            </S.TicketBlockContent>
        </S.TicketSection>
    );
}

export default BuyTicket;
